<template>

    <div v-for="(variant) in website.favorite_variants" :key="variant.id"
                  class="!no-underline group relative border-gray-100 rounded-2xl p-0 sm:p-3 cursor-pointer
                          hover:border-gray-400
                          hover:scale-105 bg-white
                        transition ease-in-out delay-150
                        text-gray-700 dark:text-white dark:bg-gray-700">

               <span class="px-2 absolute right-0 z-100 bg-white h-8 rounded" @click="addFavourite(variant.id)">
                   <CustomSvg name="Xmark"  customClass="fill-blue-800 h-2 z-100"  ></CustomSvg>
               </span>

                  <span v-if="variant.product.price_percentage!==undefined && variant.product.price_percentage!==0 && variant.product.price_percentage!=='0'" class="bg-green-700 rounded p-0.5 px-2 absolute text-white z-10">
                        {{ variant.product.price_percentage }} %
                    </span>
       <jet-nav-link :href="'/'+variant.canonical">
        <div class="group relative mx-auto w-full rounded-lg dark:bg-white p-1">
            <div class="hero container max-w-screen-lg mx-auto  h-44 w-full flex items-center max-w" >

                <img :src="variant.first_picture.PictureUrlFull" class="max-h-44 mx-auto"
                     :alt="variant.first_picture.PictureUrlFull"/>
            </div>
        </div>
        <h3 class="text-xs sm:text-sm  no-underline h-24 mt-3">
            {{ variant.product.name.substring(0, 80) }}<span v-if="variant.product.name.length > 80">...</span>
        </h3>

        <div v-if="Object.keys(variant.prices_ordered).length> 1" class="text-xs font-light float-left mt-5">
            {{ Object.keys(variant.prices_ordered).length }} prijzen
        </div>
        <div class="text-sm font-medium float-right text-sm text-xl font-bold mt-2">
            {{currency(variant.prices_ordered[0].price_discount / 100)}}
        </div>
       </jet-nav-link>
    </div>
</template>

<script>
import axios from "axios";
import JetNavLink from '@/Jetstream/NavLink.vue'
import CustomSvg from '@/Pages/Admin/Page/Templates/Partials/Svg.vue';
export default {
    components: {
        axios,
        JetNavLink,
        CustomSvg
    },
    props: {
        website: Object,
    },
}


</script>

